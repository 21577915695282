import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "title": "Philosophy"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Philosophy`}</h2>
    <p>{`Aventus, meaning “success” is a strategically focused marketing agency specializing in creative brand growth and full-spectrum advertising campaign execution.`}</p>
    <p>{`We are a motley crew of visionaries, outdoor lovers, car enthusiasts, and PGA Tour wannabe’s.`}</p>
    <p>{`Our focus is to cultivate, develop, and execute via effective advertising channels to increase ROI and bottom line.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      